import React, {useContext} from "react"
import { Link, graphql } from "gatsby"
import LayoutMaster from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import {Section} from '../components/sections'
import {GlobalStateContext} from '../context/GlobalContextProvider'
import Img from 'gatsby-image'

import section from '../styles/sections.module.css'
import flex from '../styles/flexbox.module.css'
import '../styles/blog.css'

function Blog({data, pageContext, location}) {
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const state = useContext(GlobalStateContext)
    const {menu} = state.language  

    return (
      <LayoutMaster location={location} title={siteTitle}>
        <SEO title={menu[4].text} />
        <Section className={section.light}>
          {/* <Bio /> */}
          {posts.map(({ node }, index) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                index===0 && <section key={node.fields.slug} className={`${flex.oneThird} postCard first`}>
                  {node.frontmatter.featuredImage && <div className={`coverContainer`}><Img sizes={node.frontmatter.featuredImage.childImageSharp.sizes} /></div>}
                  <small>{node.frontmatter.date}</small>
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link
                      style={{ boxShadow: `none` }}
                      to={`blog${node.fields.slug}`}
                    >
                      {title}
                    </Link>
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              )
          })}          
          <div style={{ margin: "20px 0 40px" }} className={`${flex.container} ${flex.spacing} ${flex.cols3} ${flex.wrap}`}>
            {posts.map(({ node }, index) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                index!==0 && <section key={node.fields.slug} className={`${flex.oneThird} ${section.postCard} ${flex.selfShrink}`}>
                  {node.frontmatter.featuredImage && <div className={`coverContainer`}><Img sizes={node.frontmatter.featuredImage.childImageSharp.sizes} /></div>}
                  <small>{node.frontmatter.date}</small>
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link
                      style={{ boxShadow: `none` }}
                      to={`blog${node.fields.slug}`}
                    >
                      {title}
                    </Link>
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              )
            })}
          </div>     
        </Section>
      </LayoutMaster>
    )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC } 
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }            
          }
        }
      }
    }
  }
`
